import React from 'react';
import {useOutlet} from 'reconnect.js';
import * as Generic from '../../Generic';
import {Select, Switch} from 'antd';
import {navigate} from 'gatsby';
import {Uploader} from '../../Components/Uploader';
import moment from 'moment';
import {useFilmList} from '../../Hooks/useFilmList';
import * as L from '../../Utils/Lang';
import {LINK_TYPES, LINK_TYPES_DISPLAY} from '../../constants';

function AdminBanner(props) {
  const [actions] = useOutlet('actions');

  const {filmList} = useFilmList({cinemaId: 1001});

  const FormSpec = {
    schema: {
      title: '',
      type: 'object',
      required: ['startDate', 'endDate', 'priority', 'isPublished', 'imgUrl'],
      definitions: {
        linkTypes: {
          title: 'films',
          type: 'string',
          anyOf: LINK_TYPES_DISPLAY.map((t) => ({
            type: 'string',
            enum: [t.key],
            title: t.title,
          })),
        },
        films: {
          title: 'films',
          type: 'string',
          anyOf: filmList.map((f) => ({
            type: 'string',
            enum: [f.id],
            title: L.d({zh: f.caption.title, en: f.caption.subtitle}),
          })),
        },
      },
      properties: {
        startDate: {
          title: '起日',
          type: 'string',
          format: 'date-time',
          default: new Date().toISOString(),
        },
        endDate: {
          title: '迄日',
          type: 'string',
          format: 'date-time',
          default: new Date().toISOString(),
        },
        imgUrl: {type: 'string', title: '圖片', default: ''},
        priority: {type: 'number', title: '權重', default: 0},
        isPublished: {type: 'boolean', title: '上架', default: false},
        linkType: {
          $ref: '#/definitions/linkTypes',
          title: '連結種類',
        },
      },
      dependencies: {
        linkType: {
          oneOf: [
            {
              properties: {
                linkType: {
                  enum: ['book'],
                },
                linkValue: {
                  $ref: '#/definitions/films',
                  title: '電影列表',
                },
              },
            },
            {
              properties: {
                linkType: {
                  enum: ['custom'],
                },
                linkValue: {
                  type: 'string',
                  title: '自定連結',
                  default: '',
                },
              },
            },
          ],
        },
      },
    },
    uiSchema: {
      imgUrl: {
        'ui:widget': Uploader,
      },
    },
  };

  return (
    <Generic.Resource
      spec={{
        path: '/admin/banner',
        name: '橫幅',
        primaryKey: 'id',
        actions: {
          setLoading: actions.setLoading,
          fetchRecords: actions.fetchBannerList,
          fetchRecordById: actions.fetchBannerById,
          deleteRecordById: actions.deleteBanner,
        },
        searchFields: [],
        columns: [
          {
            title: '圖片',
            key: 'imgUrl',
            dataIndex: 'imgUrl',
            render: (value) => {
              return (
                <img
                  style={{width: 100, height: 100, objectFit: 'cover'}}
                  src={value}
                  alt={value}
                />
              );
            },
          },
          {
            title: '權重',
            key: 'priority',
            dataIndex: 'priority',
            sorter: (a, b) => a.priority - b.priority,
          },
          {
            title: '起日',
            key: 'startDate',
            dataIndex: 'startDate',
            render: (value) => {
              return <div>{moment(value).format('YYYY-MM-DD HH:MM')}</div>;
            },
          },
          {
            title: '迄日',
            key: 'endDate',
            dataIndex: 'endDate',
            render: (value) => {
              return <div>{moment(value).format('YYYY-MM-DD HH:MM')}</div>;
            },
          },
          {
            title: '上架',
            key: 'isPublished',
            dataIndex: 'isPublished',
            render: (value) => {
              return <Switch defaultChecked={value} disabled />;
            },
          },
          {
            title: '連結種類',
            key: 'linkType',
            dataIndex: 'linkType',
            render: (value) => {
              return (
                <div>
                  {LINK_TYPES_DISPLAY.find((t) => t.key === value).title}
                </div>
              );
            },
          },
        ],
      }}
      renderDetail={(props) => {
        const {instance} = props;
        return (
          <Generic.Form
            schema={FormSpec.schema}
            uiSchema={FormSpec.uiSchema}
            instance={instance}
            onSubmit={async (formData) => {
              const {id} = formData;
              if (!!id) {
                await actions.updateBanner({id, values: formData});
                await navigate('/admin/banner');
              } else {
                await actions.createBanner(formData);
                await navigate('/admin/banner');
              }
            }}
          />
        );
      }}
      {...props}
    />
  );
}

export default AdminBanner;
